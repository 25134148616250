'use strict'
import dayjs from 'dayjs'
export const dateFormat = (val, fmt = 'YYYY-MM-DD') => {
  if (!val) return null
  return val === '0001-01-01T00:00:00' ? '/' : dayjs(val).format(fmt)
}
export const dateTimeFormat = (val, fmt = 'YYYY-MM-DD HH:mm:ss') => {
  if (!val) return null
  return val === '0001-01-01T00:00:00' ? '/' : dayjs(val).format(fmt)
}
export const monthFormat = (val, fmt = 'YYYY-MM') => {
  if (!val) return null
  return val === '0001-01-01T00:00:00' ? '/' : dayjs(val).format(fmt)
}
export const timesFormat = (val, fmt = 'YYYY-MM-DD HH:mm:ss') => {
  if (!val) return null
  if (val === '/') return dayjs(new Date()).format(fmt)
  return val === '0001-01-01T00:00:00' ? '/' : dayjs(val).format(fmt)
}
