<template>
  <div class="new-warrper-cont">
    <div class="new-warrper-con-items">
      <div class="item-wrapper" v-for="item in list" :key="item.id">
        <div class="item-wrapper-cover" :style="`background-image: url('${item.file_url}');`"></div>
        <div class="item-wrapper-words">
          <div class="item-wrapper-words-title" @click="handleToUrl(item.id)">{{item.title}}</div>
          <div class="item-wrapper-words-time">{{item?.time}}</div>
          <div class="item-wrapper-words-desc">{{item.brief_introduction}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
export default {
  props: ['list'],
  setup () {
    const router = useRouter()
    const handleToUrl = (id) => {
      router.push({ path: '/details', query: { detailsId: id } })
      console.log('%c 🍓 router: ', 'font-size:20px;background-color: #ED9EC7;color:#fff;', router)
    }
    return { handleToUrl }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/mixin.scss';
@import '@/assets/scss/variable.scss';
.new-warrper-cont {
  @include flex-center(space-between);
  &-items {
    flex: 1;
    width: 100%;
  }
}
.item-wrapper {
  @include flex-center(space-between, center, nowrap, row-reverse);
  position: relative;
  margin-bottom: 0.4rem;
  &-cover {
    width: 36.48vw;
    max-width: 48%;
    height: 20.59vw;
    max-height: 2.59rem;
    background-size: 100% 100%;
    background-color: rgb(255, 255, 255);
  }
  &-words {
    width: 36.48vw;
    max-width: 48%;
    &-title {
      color: rgb(60, 140, 255);
      font-size: 0.32rem;
      cursor: pointer;
      font-family: "Gilroy-Bold",-apple-system,"Sofia Pro","Microsoft YaHei","Helvetica","Arial",sans-serif;
      font-weight: 700;
      line-height: 1.41;
    }
    &-time {
      margin-top: .1rem;
      margin-bottom: .1rem;
      color: rgb(130, 130, 130);
      font-size: .14rem;
    }
    &-desc {
      font-size: 0.213rem;
    }
  }
}
</style>
