<template>
  <div class="new-warrper">
    <p class="new-warrper-title">招标信息</p>
    <NewsList :list="news" />
    <Pagination :totalPage="TotalCount" :current="PageIndex" :pageSizeNum="PageSize" />
  </div>
</template>

<script>
import config from '@/utils/request/config'
import { GetNewsPageData } from '@/api/WsIndexDatasApi'
import NewsList from '@/components/CardList'
import { useRoute, useRouter } from 'vue-router'
import { reactive, ref, toRefs } from 'vue'
import { timesFormat } from '@/utils/dateFormat'
const initEfftect = () => {
  const route = useRoute()
  const { navId } = route.query
  const pageInfo = reactive({
    PageIndex: 1,
    PageSize: 10,
    TotalCount: 0
  })
  const news = ref([])
  const initApi = async () => {
    const target = {
      cate_id: navId,
      page_info: {
        PageIndex: pageInfo.PageIndex,
        PageSize: pageInfo.PageSize
      }
    }
    const { data: { Data, PageInfo } } = await GetNewsPageData(target)
    Data.forEach(item => {
      item.file_url = config.hostUrl + item.file_url
      item.time = timesFormat(item.create_time)
    })
    news.value = Data
    Object.keys(pageInfo).forEach(key => {
      pageInfo[key] = PageInfo[key]
    })
  }
  initApi()
  return { pageInfo, news }
}
export default {
  setup () {
    const router = useRouter()
    const { pageInfo, news } = initEfftect()

    const handleToDes = (id) => {
      router.push({ path: '/details', query: { detailsId: id } })
    }
    return { news, ...toRefs(pageInfo), handleToDes }
  },
  components: {
    NewsList
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/mixin.scss';
@import '@/assets/scss/variable.scss';
.new-warrper {
  position: relative;
  min-height: calc(100vh - 1rem);
  margin: auto;
  max-width: 9.55rem !important;
  padding: .59rem 16vw .78rem;
  box-sizing: content-box;
  &-title {
    font-size: .48rem;
    margin-left: -8vw;
    margin-bottom: .49rem;
    font-family: "Gilroy-Bold",-apple-system,"Sofia Pro","Microsoft YaHei","Helvetica","Arial",sans-serif;
    font-weight: 700;
    line-height: 1.41;
  }
  &-top {
    width: 100%;
    margin: auto;
    margin-bottom: .65rem;
    text-align: left;
    &-cover {
      width: 100%;
      min-height: 3.5rem;
      margin-bottom: .24rem;
      object-fit: contain;
    }
    &-title {
      font-size: .34rem;
      line-height: 1.41;
      margin-bottom: .1rem;
      color: #3c8cff;
      cursor: pointer;
      font-family: "Gilroy-Bold",-apple-system,"Sofia Pro","Microsoft YaHei","Helvetica","Arial",sans-serif;
      font-weight: 700;
    }
    &-desc {

    }
  }
  &-cont {

  }
}
</style>
